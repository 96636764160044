import React, { useContext, useEffect, useMemo } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import HomeBackgroundSection from "../components/sections/home-background-section";
import { Helmet } from "react-helmet";
import withLocation from "../components/shared/withLocation";
import CartContext from "../context/cart-context";
import { GatsbyImage } from "gatsby-plugin-image";
import useAllProducts from "../hooks/useAllProduct";

const Boxes = ({ boxes = [] }) => {
  return (
    <div className="md:grid grid-cols-2 auto-rows-fr gap-4 my-6">
      {boxes.map((box, index) => {
        if (box.image) {
          return (
            <GatsbyImage
              image={box.image.gatsbyImage}
              alt={box.image.altText}
              key={`box-${index}`}
              className="rounded-lg w-full"
            />
          );
        }

        return (
          <div
            key={`box-${index}`}
            className="bg-grey-lighter rounded-lg p-4 lg:p-6"
          >
            <GatsbyImage
              image={box.icon.gatsbyImage}
              alt="icon"
              className="mb-3 w-16"
            />
            <div
              className="wp-content"
              dangerouslySetInnerHTML={{
                __html: box.content,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

const IndexPage = ({ data, search }) => {
  const { setDiscountCode } = useContext(CartContext);
  const products = useAllProducts();

  const covers = useMemo(() => {
    if (!products) return [];

    const standardSteel = products.find(
      ({ node }) => node.product.sku === "window-well-cover-steel-standard"
    );
    const standardPoly = products.find(
      ({ node }) => node.product.sku === "window-well-cover-poly-standard"
    );

    return products
      .filter(
        ({ node }) =>
          node.product.sku === "window-well-cover-steel-premium" ||
          node.product.sku === "window-well-cover-poly-premium"
      )
      .map(({ node }) => {
        const url = node.product.sku.includes("steel")
          ? "/steel-window-well-covers/"
          : "/plastic-polycarbonate-window-well-covers/";

        const price = node.product.sku.includes("steel")
          ? standardSteel.node.product.salePrice
          : standardPoly.node.product.salePrice;
        return {
          id: node.id,
          title: node.title.replace("Premium ", ""),
          url: url,
          image: node.featuredImage.node.localFile.publicURL,
          price: price,
        };
      });
  }, [products]);

  useEffect(() => {
    const getDiscount = async () => {
      setDiscountCode(search.code);
    };
    if (search.code) {
      getDiscount();
    }
  }, [search, setDiscountCode]);

  const acfData = data.wpPage;

  return (
    <Layout>
      <Seo
        title={acfData.seo.title}
        keywords={[`window well covers`, `steel window covers`, `window cover`]}
        description="Keep your family safe with our custom window well covers. We offer polycarbonate and steel window well covers that keep the elements and debris out. Contact us to learn more!"
      />
      <Helmet
        bodyAttributes={{
          class: "home-page",
        }}
      />

      <HomeBackgroundSection
        className="home-background-section"
        title={acfData.pages.h1Title}
      />

      <div className="container mx-auto flex flex-wrap">
        <div className="w-full grid gap-6 md:grid-cols-2 lg:mt-[-80px] relative z-10 mb-6 lg:mb-12">
          {covers &&
            covers.map((cover) => {
              return (
                <div
                  className="bg-grey-lighter p-4 pt-3 rounded-xl flex flex-col"
                  key={cover.id}
                >
                  <h3 className="font-bold text-center mb-2 flex-1">
                    {cover.title}
                  </h3>
                  <div className="rounded-lg overflow-hidden">
                    <Link to={cover.url}>
                      <img
                        src={cover.image}
                        alt={cover.title}
                        className="h-64 w-full object-cover object-bottom"
                      />
                    </Link>
                    <div className="bg-white p-4">
                      <div className="text-xl font-semibold mb-3">
                        <span className="text-grey">Starting at:</span> $
                        {cover.price}
                      </div>
                      <Link
                        to={cover.url}
                        className={`bg-${process.env.THEME_COLOR_PRIMARY} text-white mb-3 py-2 px-4 rounded no-underline inline-block`}
                      >
                        Shop now
                      </Link>
                      <div className="text-xs text-grey-dark">
                        * Expert installation add-on available at checkout
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {acfData.layout.homePage.homepagesections.map((section, index) => {
          if (index === 2) {
            return (
              <>
                <h2 className="lg:text-3xl max-w-sm">
                  Covering Window Wells in Utah since{" "}
                  <span className={`text-${process.env.THEME_COLOR_PRIMARY}`}>
                    2002
                  </span>
                </h2>
                <Boxes boxes={acfData.layout.homePage.boxes} />
                <div
                  className={`rounded-xl md:flex items-center gap-12 p-6 lg:p-10 bg-grey-lighter mb-6 lg:my-12 ${
                    index % 2 === 0 ? "" : "flex-row-reverse"
                  }`}
                >
                  <div className="md:w-2/5">
                    <GatsbyImage
                      alt={section.image.altText}
                      image={section.image.gatsbyImage}
                      className="rounded-lg w-full h-96"
                    />
                  </div>
                  <div
                    className="wp-content flex-1 my-4"
                    dangerouslySetInnerHTML={{
                      __html: section.content,
                    }}
                  />
                </div>
              </>
            );
          }
          return (
            <div
              className={`rounded-xl md:flex items-center gap-12 p-6 lg:p-10 bg-grey-lighter mb-6 lg:my-12 ${
                index % 2 === 0 ? "" : "flex-row-reverse"
              }`}
            >
              <div className="md:w-2/5">
                <GatsbyImage
                  alt={section.image.altText}
                  image={section.image.gatsbyImage}
                  className="rounded-lg w-full h-96"
                />
              </div>
              <div
                className="wp-content flex-1 my-4"
                dangerouslySetInnerHTML={{
                  __html: section.content,
                }}
              />
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default withLocation(IndexPage);

export const pageQuery = graphql`
  query HomePageQuery {
    wpPage(slug: { eq: "home" }) {
      id
      title
      slug
      status
      featuredImage {
        node {
          title
          localFile {
            publicURL
          }
        }
      }
      pages {
        h1Title
      }
      layout {
        homePage {
          homepagesections {
            image {
              altText
              gatsbyImage(width: 800)
            }
            content
          }
          boxes {
            image {
              altText
              gatsbyImage(width: 800)
            }
            icon {
              gatsbyImage(width: 200)
            }
            content
          }
        }
      }
      seo {
        title
        metaDesc
        canonical
      }
    }
  }
`;
