import React from "react";
import { graphql, StaticQuery } from "gatsby";

import BackgroundImage from "gatsby-background-image";

const HomeBackgroundSection = ({ className, title }) => (
  <StaticQuery
    query={graphql`
      {
        desktop: file(relativePath: { eq: "home-hero.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage Tag="section" className={className} fluid={imageData}>
          <div className="lg:h-[600px] flex items-center py-16">
            <div className="container mx-auto text-left">
              <h1 className="text-2xl lg:text-6xl font-semibold text-white text-shadow-lg max-w-lg lg:mb-16">
                {title}
              </h1>
            </div>
          </div>
        </BackgroundImage>
      );
    }}
  />
);

export default HomeBackgroundSection;
